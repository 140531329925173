.container {
  margin: 0px auto;
  max-width: 800px;
}

h1 {
  text-align: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.col-md-6 {
  flex: 0 0 calc(50% - 15px);
  margin-right: 30px;
}

.image-box {
  border: 1px dashed #ddd;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-box img {
  max-width: 100%;
  max-height: 100%;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #ddd;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.placeholder span {
  cursor: pointer;
  background-color: #4CAF50;
  text-align: center;
  width: 250px;
  margin-bottom: 20px;
}

.buttons-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.upload-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

input[type="text"],
textarea,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

/* input[type="file"] {
  display: none;
} */

.success-message {
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 767px) {
  .col-md-6 {
    flex: 0 0 100%;
    margin-right: 0;
  }
}
