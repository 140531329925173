.registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .form-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
  }
  
  .form-input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
    width: 100%;
    max-width: 400px;
  }
  
  .form-input:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  .password-input-wrapper {
    position: relative;
    width: 100%;
  }
  
  .password-input {
    padding-right: 40px;
  }
  
  .password-toggle-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    color: #666;
    cursor: pointer;
  }
  
  .password-toggle-button:hover {
    color: #333;
  }
  
  .form-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    background-color: #0099ff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    width: 100%;
    max-width: 400px;
  }
  
  .form-button:hover {
    background-color: #0077cc;
  }
  