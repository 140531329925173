.app {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    background-color: #f7fffd !important;
    /* background-image: url('../public/Poster.webp');
    background-repeat: no-repeat;
    background-size: cover;  */
   }
  .title {
    text-align: justify;
  }
  
  input[type="text"],
  input[type="password"] {
    height: 55px;
    font-size: 14px !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .input-container {
    display: flex;
    position: relative;
    margin: 20px;
  }
  
  input[type="password"] {
    padding-right: 40px; /* Add padding to accommodate the eye icon */
  }
  
  .password-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  
  input[type="submit"] {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: rgb(241 173 179);
    border: 1px solid rgb(241 173 179);
    color: #fff;
    padding: 10px 30px;
  }
  
  input[type="submit"]:hover {
    background: #6cf0c2;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .login-form {
    background-color: white;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .list-container {
    display: flex;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  
  .title {
    font-size: 25px;
    margin-bottom: 20px;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin: 20px;
  }

  @media (min-width: 300px){
    .container{
      max-width: 100% !important;
      padding: 0px;
    }
  }
  
  @media (min-width: 1400px){
    .container{
      max-width: 100% !important;
    }
  }